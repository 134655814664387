

import React from "react"
import { graphql } from 'gatsby'
import Wrapper from "../../components/insight_wrapper"
import PortableText from '../../components/portableText'
import Img from 'gatsby-image'
import { getFluidGatsbyImage } from 'gatsby-source-sanity'

import { Helmet } from "react-helmet"

function GetBannerImage(props) {
  if (props.mainImage) return (

    <Img fluid={props.mainImage.asset.fluid} />

  )
  else {
    return null
  }
}


export const query = graphql`query PostQuery ($id :String) {
  post: sanityPost(id: {eq: $id }) {
    id
    title
    slug {current}
    _rawBody
    excerpt
    mainImage {
          asset {
            originalFilename
            path
            url
            fluid(maxWidth: 368, sizes: "184, 368") {
              ...GatsbySanityImageFluid
            }
          }
        }
  }
}
`


const Page = props => {

  const { data, errors } = props

  if (errors) {
    return (
      <Wrapper>
        Big Error Here

   </Wrapper>
    )
  }


  return (

    <Wrapper
      title={data.post.title}
      banner={GetBannerImage(data.post)}
      bannerAlt="Patient and Doctor consulting via telehealth"   >

      <Helmet>
        <meta property='og:title' content={data.post.title} />
        <meta property='og:image' content={data.post.mainImage.asset.url} />
        <meta property='og:description' content={data.post.excerpt} />
        <meta property='og:url' content={`https://msmhealth.com/news_and_insights/dynamic/${data.post.slug.current}`} />



      </Helmet>


      {data.post._rawBody && <PortableText blocks={data.post._rawBody} />}




    </Wrapper >

  )
}

export default Page;